import useLocalStorage from 'use-local-storage'

import Dropdown, { Option } from 'react-dropdown'

import 'react-dropdown/style.css'
import './index.css'

enum ThemeOptions {
  Light = 'light',
  Dark = 'dark',
  Blue = 'blue',
}

function Header() {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [theme, setTheme] = useLocalStorage<string>(
    'theme',
    'blue' // force blue for now // defaultDark ? 'dark' : 'light',
  )
  const options = [ThemeOptions.Light, ThemeOptions.Dark, ThemeOptions.Blue]
  const defaultOption = theme || options[0]

  const switchTheme = (arg: Option) => {
    const COLORS: Record<string, {
      background: string;
      primary: string;
      secondary: string;
      accent: string;
    }> = {
      light: {
        background: 'white',
        primary: 'black',
        secondary: 'grey',
        accent: 'darkred'
      },
      dark: {
        background: '#292a2d',
        primary: 'white',
        secondary: 'grey',
        accent: 'darkred'
      },
      blue: {
        background: '#326fa8',
        primary: 'white',
        secondary: 'grey',
        accent: 'darkred'
      }
    }
    const root = window.document.documentElement;
    root.style.setProperty(
      '--background',
      COLORS[arg.value].background
    );
    root.style.setProperty(
      '--text-primary',
      COLORS[arg.value].primary
    );
    root.style.setProperty(
      '--text-secondary',
      COLORS[arg.value].secondary
    );
    root.style.setProperty(
      '--accent',
      COLORS[theme].accent
    );
    setTheme(arg.value)
  }

  return (
    <div className="header">
      <Dropdown
        options={options}
        onChange={(arg) => switchTheme(arg)}
        value={defaultOption}
        placeholder="Select an option"
      />
    </div>
  )
}

export default Header
