import DayTracker from './components/DayTracker'
import Header from './components/Header'

import './App.css'
import 'react-dropdown/style.css'

function App() {
  return (
    <div className="App">
      <Header />
      <DayTracker />
    </div>
  )
}

export default App
