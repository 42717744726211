import { useState, useMemo } from 'react'
import Holidays, { HolidaysTypes } from 'date-holidays'
import InfiniteScroll from 'react-infinite-scroll-component'

import './index.css'

const usHolidays = new Holidays('US', 'ca')
const krHolidays = new Holidays('KR')

type HolidaySupported = 'US' | 'KR'
const cachedHolidays: Record<
  HolidaySupported,
  Record<number, HolidaysTypes.Holiday[]>
> = {
  US: {},
  KR: {},
}

function roundUpToNearest100(num: number) {
  return Math.ceil(num / 100) * 100
}

function calculateMilestones(numOfMilestones: number, startingDate: Date) {
  const milestones = []
  const today = new Date()
  const diffInMs = today.getTime() - startingDate.getTime()
  const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24))
  for (let i = 0; i < numOfMilestones; i++) {
    const days = roundUpToNearest100(diffInDays) + i * 100
    const date = new Date(startingDate.getTime() + days * 1000 * 3600 * 24)

    let holidayInfo

    if (!cachedHolidays.US[date.getFullYear()]) {
      cachedHolidays.US = {}
      cachedHolidays.US[date.getFullYear()] = usHolidays.getHolidays(
        date.getFullYear(),
      )
    }
    cachedHolidays.US[date.getFullYear()].forEach((holiday) => {
      if (
        holiday.start.getTime() <= date.getTime() &&
        date.getTime() <= holiday.end.getTime()
      ) {
        holidayInfo = holiday
      }
    })

    if (!cachedHolidays.KR[date.getFullYear()]) {
      cachedHolidays.KR = {}
      cachedHolidays.KR[date.getFullYear()] = krHolidays.getHolidays(
        date.getFullYear(),
      )
    }
    cachedHolidays.KR[date.getFullYear()].forEach((holiday) => {
      if (
        holiday.start.getTime() <= date.getTime() &&
        date.getTime() <= holiday.end.getTime()
      ) {
        holidayInfo = holiday
      }
    })

    milestones.push({
      days,
      date,
      holiday: holidayInfo,
    })
  }
  return milestones
}

interface Milestone {
  days: number
  date: Date
  holiday?: HolidaysTypes.Holiday
}

function DayTracker() {
  const [milestones, setMilestones] = useState<Milestone[]>([])
  const [numOfMilestones, setNumOfMilestones] = useState<number>(10)
  const [milestoneInput, setMilestoneInput] = useState<string>('10')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [startingDate] = useState<Date>(
    new Date('Feb 07 2021 00:00:00 GMT-0800'),
  )

  const today = new Date()
  const diffInMs = today.getTime() - startingDate.getTime()
  const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24))

  const useInfiniteScroll = true

  const formatOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  useMemo(() => {
    if (Number.isInteger(numOfMilestones)) {
      const milestones = calculateMilestones(numOfMilestones, startingDate)
      setMilestones(milestones)
    }
  }, [numOfMilestones, startingDate])

  const handleSetNumOfMilestones = (numOfMilestones: string) => {
    setErrorMessage('')
    setMilestones([])
    const parseNumOfMilestones = parseInt(numOfMilestones)
    if (
      Number.isInteger(parseNumOfMilestones) &&
      !Number.isNaN(parseNumOfMilestones)
    ) {
      if (parseNumOfMilestones > 100) {
        setErrorMessage('Too many milestones!')
      } else {
        setNumOfMilestones(parseNumOfMilestones)
      }
    } else {
      setNumOfMilestones(NaN)
      setErrorMessage('Invalid number of milestones!')
    }
  }

  const fetchData = () => {
    setNumOfMilestones(numOfMilestones + 10)
  }

  const renderMilestones = () => {
    return milestones.map((milestone, index) => {
      return (
        <div key={index}>
          <h4>
            <p>
              {milestone.days}:{' '}
              {milestone.date.toLocaleDateString('en-US', formatOptions)}
            </p>
            {milestone.holiday && <p>{milestone.holiday.name}</p>}
          </h4>
        </div>
      )
    })
  }

  return (
    <div className="DayTracker">
      <div className="days-since">
        <h1>Derek and Jkay</h1>
        <h4>
          Days since {startingDate.toLocaleDateString('en-US', formatOptions)}
        </h4>
        <h2>{diffInDays} Days</h2>
      </div>
      <div className="milestones">
        <div className="milestones-header">
          <h2>Next Milestones</h2>

          {!useInfiniteScroll && (
            <>
              <input
                value={milestoneInput}
                onChange={(e) => {
                  setMilestoneInput(e.target.value)
                  handleSetNumOfMilestones(e.target.value)
                }}
              />
              <div className="milestones-inputs">
                <button
                  disabled={errorMessage !== ''}
                  onClick={() => {
                    setNumOfMilestones(numOfMilestones + 1)
                    setMilestoneInput((numOfMilestones + 1).toString())
                  }}
                >
                  +
                </button>
                <button
                  disabled={errorMessage !== ''}
                  onClick={() => {
                    setNumOfMilestones(numOfMilestones - 1)
                    setMilestoneInput((numOfMilestones - 1).toString())
                  }}
                >
                  -
                </button>
              </div>
              <p>{errorMessage}</p>
            </>
          )}
        </div>

        <div className="milestones-body">
          {useInfiniteScroll && (
            <InfiniteScroll
              dataLength={milestones.length}
              next={fetchData}
              hasMore={true}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {renderMilestones()}
            </InfiniteScroll>
          )}

          {!useInfiniteScroll && renderMilestones()}
        </div>
      </div>
    </div>
  )
}

export default DayTracker
